// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]7.0-rc-11289(5f7c84f5b8)-C14/02/2025-14:52:27-B14/02/2025-15:12:41' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]7.0-rc-11289(5f7c84f5b8)-C14/02/2025-14:52:27-B14/02/2025-15:12:41",
  branch: "master",
  latestTag: "7.0-rc",
  revCount: "11289",
  shortHash: "5f7c84f5b8",
  longHash: "5f7c84f5b8bb3132b59d658311ed425d05a3399f",
  dateCommit: "14/02/2025-14:52:27",
  dateBuild: "14/02/2025-15:12:41",
  buildType: "Ansible",
  } ;
